import React, { useState, useEffect, Fragment } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Select from 'react-select';

import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import Title from '../../components/layout/title';
import { H1, H2, H3, H4 } from '../../components/layout/headings';
import { Collapsible } from '../../components/layout/collapsible';

import ScrollSpy, { MOBILE_HEADER_OFFSET } from '../../components/scrollSpy';
import useIsPageScrolled from '../../hooks/useIsPageScrolled';

const CommissionList = ({ commissions, commissionSeats, filter }) => {
  const seatsByCommission = commissionSeats.nodes.reduce((acc, seat) => {
    if (!acc[seat.Committee_Name]) {
      acc[seat.Committee_Name] = [];
    }
    acc[seat.Committee_Name].push(seat);
    return acc;
  }, {});

  return (
    <div className="flex flex-col gap-4">
      {commissions.nodes.map((commission) => (
        <Commission
          commission={commission}
          key={commission.Committee_Name}
          seats={seatsByCommission[commission.Committee_Name]}
          filter={filter}
        />
      ))}
    </div>
  );
};

const Commission = ({ commission, seats, filter }) => {
  const dataKeys = {
    Has_Vacancy: { headerName: 'Has Vacancy' },
    Category: {
      headerName: 'Category',
      filterer: (row) => filter.categories.length > 0 && !filter.categories.includes(row.Category),
    },
    City_Affiliation: { headerName: 'City Affiliation' },
    Civil_Grand_Jury_Recommendation: { headerName: 'Civil Grand Jury Recommendation' },
    Floating_Qualifications: { headerName: 'Commission Qualifications' },
    Committee_Description_Summary: { headerName: 'Committee Description Summary' },
    Compensation_Detail: { headerName: 'Compensation Detail' },
    Created_by_Charter__Ordinance__or_Other: {
      headerName: 'Created by Charter, Ordinance, or Other',
    },
    Decision_making___Advisory: { headerName: 'Decision making / Advisory' },
    Eligible_for_Compensation: { headerName: 'Eligible for Compensation' },
    Eligible_for_Health_Benefits: { headerName: 'Eligible for Health Benefits' },
    Form_700_Filing_Requirement: { headerName: 'Form 700 Filing Requirement' },
    Link_to_Reports: {
      headerName: 'Link to Reports',
      parser: (value) => (value ? <a href={value}>{value}</a> : null),
    },
    Presumed_Active_Inactive: { headerName: 'Presumed Active/Inactive' },
    Priority: { headerName: 'Priority' },
    Required_Meetings_Per_Year: { headerName: 'Required Meetings Per Year' },
    Seat_holder: { headerName: 'Seat holder' },
    Experience___Interest: {
      headerName: 'Experience & Interest',
      filterer: (row) =>
        filter.interests.length > 0 &&
        !filter.interests.some((interest) => row.Experience___Interest.includes(interest)),
    },
    Start_Date: { headerName: 'Start Date' },
    Term_of_Office: { headerName: 'Term of Office' },
    Website: {
      headerName: 'Website',
      parser: (value) => (value ? <a href={value}>{value}</a> : null),
    },
  };

  const item = (key) => {
    if (!commission[key]) {
      return null;
    }
    const item = dataKeys[key];
    return (
      <div className="text-sm">
        <strong>{item.headerName}</strong>:{' '}
        {item.parser ? item.parser(commission[key]) : commission[key]}
      </div>
    );
  };
  // Filter out commissions that don't match the filter
  if (
    Object.entries(dataKeys).some(([, value]) => {
      return value.filterer && value.filterer(commission);
    })
  )
    return null;
  const seatsDiv = seats && Seats({ seats: seats, filter: filter });
  if (seatsDiv === null) {
    return null;
  }
  return (
    <div className="py-4">
      <H3>{commission.Committee_Name}</H3>
      <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-gray-300">
        {commission.Category}
      </span>
      {commission.Has_Vacancy === 'No' ? (
        <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-md dark:bg-red-700 dark:text-red-300">
          No Vacancy
        </span>
      ) : (
        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-md dark:bg-green-700 dark:text-green-300">
          Has Vacancy
        </span>
      )}
      <p>{commission.Simplifed_Committee_Description}</p>
      {item('Website')}
      {item('Link_to_Reports')}
      <Collapsible>
        <div className="mb-2 prose">
          <h4>Requirements</h4>
          {item('Floating_Qualifications')}
          {item('Required_Meetings_Per_Year')}
          {item('Form_700_Filing_Requirement')}
        </div>
        <div className="mb-2 prose">
          <h4>Commission Information</h4>
          {item('City_Affiliation')}
          {item('Start_Date')}
          {item('Term_of_Office')}
          {item('Decision_making___Advisory')}
          {item('Created_by_Charter__Ordinance__or_Other')}
        </div>
        <div className="mb-2 prose">
          <h4>Compensation & Benefits</h4>
          {item('Eligible_for_Compensation')}
          {item('Compensation_Detail')}
          {item('Eligible_for_Health_Benefits')}
        </div>
      </Collapsible>
      {seatsDiv}
    </div>
  );
};

const numSeatsShown = ({ data, cols, filter }) => {
  return data.filter((row) => !cols.some((col) => col.filterer && col.filterer(row))).length;
};

const Seats = ({ seats, filter }) => {
  const seatsCols = [
    { key: 'Seat_Name__formula_', label: 'Seat Name' },
    { key: 'Seat_Identifier', label: 'Seat Identifier' },
    {
      key: 'Seat_Holder',
      label: 'Seat Holder',
      filterer: (row) => filter.hideNonVacant && !row.Seat_Holder.toLowerCase().includes('vacant'),
    },
    { key: 'Term_Ending', label: 'Term Ending' },
    { key: 'Seat_Specific_Qualifications', label: 'Seat Qualifications' },
    {
      key: 'Open_to_the_General_Public',
      label: 'Open to Non-City Employees?',
      filterer: (row) => filter.excludeEmployees && row.Open_to_the_General_Public === 'No',
    },
  ];
  if (numSeatsShown({ data: seats, cols: seatsCols, filter }) === 0) {
    return null;
  }
  return (
    <div className="w-full not-prose">
      <h5>Seats</h5>
      <div className="w-full divide-y divide-gray-200">
        {seats
          .sort(function (a, b) {
            return Number(a.Seat_Identifier) - Number(b.Seat_Identifier);
          })
          .map((seat) => {
            if (seatsCols.some((col) => col.filterer && col.filterer(seat))) {
              return null;
            }
            const hasQualifications =
              seat.Seat_Specific_Qualifications && seat.Seat_Specific_Qualifications !== '';
            return (
              <div
                key={seat.Seat_Name__formula_}
                className="text-sm pb-2 flex flex-col sm:flex-row"
              >
                <div className={`w-full ${hasQualifications ? 'sm:w-1/2' : ''}`}>
                  {seat.Seat_Name__formula_ && <strong>{seat.Seat_Name__formula_}</strong>}
                  {seat.Seat_Holder.toLowerCase().includes('vacant') ? (
                    <div>
                      <span className="text-red-500">
                        {seat.Seat_Holder.replace('VACANT', 'Vacant')}
                      </span>
                    </div>
                  ) : (
                    <div>Currently held by {seat.Seat_Holder}</div>
                  )}
                  <p>
                    {seat.Term_Ending.toLowerCase().includes('indefinite') ? (
                      <Fragment>Indefinite term</Fragment>
                    ) : (
                      <div>Term ends {seat.Term_Ending}</div>
                    )}
                  </p>
                  {seat.Open_to_the_General_Public.toLowerCase() === 'no' && (
                    <p className="text-red-500">Only open to City employees</p>
                  )}
                </div>
                {hasQualifications && (
                  <div className="w-full sm:w-1/2 max-h-20 overflow-y-auto bg-brand-blue-1 sm:bg-white">
                    <p>
                      <strong>Qualifications</strong>
                      <br />
                      {seat.Seat_Specific_Qualifications}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const TrackerPage = ({ location, data }) => {
  const [filter, setFilter] = useState({
    hideNonVacant: false,
    excludeEmployees: false,
    categories: [],
    interests: [],
  });

  const handleCheckboxChange = (key) => (event) => {
    setFilter((prev) => ({
      ...prev,
      [key]: event.target.checked, // Dynamically update the key
    }));
  };

  const handleSelectBoxChange = (selected) => {
    setFilter((prev) => ({
      ...prev,
      categories: selected.map((s) => s.value),
    }));
  };

  const handleInterestSelectBoxChange = (selected) => {
    setFilter((prev) => ({
      ...prev,
      interests: selected.map((s) => s.value),
    }));
  };

  const previewImage = data.previewImage.childImageSharp.resize;
  const isPageScrolled = useIsPageScrolled(1000);
  const { allCommitteesCsv = {}, allSeatsCsv = {} } = data;
  const vacantCommissions = {
    nodes: allCommitteesCsv.nodes.filter((c) => c.Has_Vacancy === 'Yes'),
  };
  const nonVacantCommissions = {
    nodes: allCommitteesCsv.nodes.filter((c) => c.Has_Vacancy !== 'Yes'),
  };

  const categories = [...new Set(allCommitteesCsv.nodes.map((obj) => obj.Category))]
    .map((category) => ({
      value: category,
      label: category,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  // TODO: parse and make Experience___Interest a multi-select
  const interests = [
    ...new Set(
      allCommitteesCsv.nodes.flatMap((obj) =>
        obj.Experience___Interest
          ? obj.Experience___Interest.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g).map((interest) =>
              interest
                .trim()
                .replace(/(^"|"$)/g, '')
                .trim()
            )
          : []
      )
    ),
  ]
    .map((interest) => ({
      value: interest,
      label: interest,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Layout>
      <SEO
        title="GrowSF Talent Commission Tracker"
        description="A database of every Commission in San Francisco. Learn what commission seats are vacant."
        pathname={location.pathname}
        image={previewImage}
      />

      <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8 pb-8">
        <Title title="Commission Tracker">
          <strong className="text-balance">Every commission in San Francisco</strong>
        </Title>
        <div className="overflow-x-clip -mx-5 px-5">
          <div className="w-full gap-4 max-w-7xl mx-auto justify-between xl:px-8 lg:grid lg:grid-cols-11">
            <div className="z-40 sticky top-0 lg:self-start lg:col-span-3 lg:w-auto lg:block">
              <ScrollSpy
                showContent={isPageScrolled}
                updateOnChange={true}
                className="h-full"
                preComponents={
                  <div className="!not-prose bg-white z-50 py-4 pr-4 pl-4 lg:pl-0">
                    <form className="space-y-4">
                      <div class="flex flex-row gap-2 lg:flex-col">
                        <div className="flex items-center space-x-2 w-full">
                          <input
                            id="hideNonVacant"
                            type="checkbox"
                            checked={filter.hideNonVacant}
                            onChange={handleCheckboxChange('hideNonVacant')}
                            className="form-checkbox h-4 w-4 text-blue-600"
                          />
                          <label
                            htmlFor="hideNonVacant"
                            className="text-sm font-medium text-gray-700"
                          >
                            Show only vacant seats
                          </label>
                        </div>
                        <div className="flex items-center space-x-2 w-full">
                          <input
                            id="excludeEmployees"
                            type="checkbox"
                            checked={filter.excludeEmployees}
                            onChange={handleCheckboxChange('excludeEmployees')}
                            className="form-checkbox h-4 w-4 text-blue-600"
                          />
                          <label
                            htmlFor="excludeEmployees"
                            className="text-sm font-medium text-gray-700"
                          >
                            Exclude employees-only seats
                          </label>
                        </div>
                      </div>
                      <div class="flex flex-row gap-2 lg:flex-col">
                        <Select
                          placeholder="Filter by category"
                          className="text-sm w-full"
                          closeMenuOnSelect={false}
                          isMulti
                          isClearable
                          options={categories}
                          selected={filter.categories}
                          onChange={handleSelectBoxChange}
                        />
                        <Select
                          placeholder="Filter by experience or interest"
                          className="text-sm w-full"
                          closeMenuOnSelect={false}
                          isMulti
                          isClearable
                          options={interests}
                          selected={filter.interests}
                          onChange={handleInterestSelectBoxChange}
                        />
                      </div>
                    </form>
                  </div>
                }
              />
            </div>
            <div
              className="voters mx-auto prose sm:prose-sm md:prose-base lg:prose-lg lg:col-span-8 tracking-wide"
              data-js-body
            >
              <p>
                Joining a Board, Commission, or Task force is an important way to participate in
                local government - but the process of finding the right one can be confusing.
              </p>
              <p>
                San Francisco has over 100 Boards, Commissions, and Task Forces - more than{' '}
                <a href="https://www.sf.gov/sites/default/files/2024-06/Commissions%20Impossible%20Report.pdf">
                  twice any comparable city and county
                </a>
                . There isn’t a single, centralized list of commissions or their vacancies. So, we
                decided to make one.
              </p>
              <p>
                Please note: <strong>we expect this database to contain errors!</strong> Each
                appointing authority keeps their own list of commission seats in PDF and other
                formats, and sometimes they’re only updated once per year. There is no up-to-date,
                centralized source of truth. We'll need your help to keep this information up to
                date! If you spot an error, please{' '}
                <a href="mailto:talent@growsf.org?subject=Error%20in%20tracker">email us</a>!
              </p>
              <p>
                If you find a commission you're interested in, head to their website to learn more
                about the application process - or{' '}
                <a href="mailto:talent@growsf.org">reach out to us</a>, and we can guide you through
                it. If you have any other questions or just want to get in touch,{' '}
                <a href="mailto:talent@growsf.org">email us</a>!.
              </p>

              <CommissionList
                commissions={allCommitteesCsv}
                commissionSeats={allSeatsCsv}
                filter={filter}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TrackerPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    previewImage: file(relativePath: { eq: "talent/growsf-tracker.png" }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
    allCommitteesCsv(
      sort: { fields: Committee_Name }
      filter: { Presumed_Active_Inactive: { ne: "Inactive" } }
    ) {
      nodes {
        Committee_Name
        Category
        City_Affiliation
        Civil_Grand_Jury_Recommendation
        Floating_Qualifications
        Committee_Description_Summary
        Compensation_Detail
        Created_by_Charter__Ordinance__or_Other
        Decision_making___Advisory
        Eligible_for_Compensation
        Eligible_for_Health_Benefits
        Form_700_Filing_Requirement
        Has_Vacancy
        Link_to_Reports
        Presumed_Active_Inactive
        Priority
        Required_Meetings_Per_Year
        Seat_holder
        Experience___Interest
        Simplifed_Committee_Description
        Start_Date
        Term_of_Office
        Website
      }
    }
    allSeatsCsv(sort: { fields: [Seat_Identifier, Seat_Name__formula_], order: ASC }) {
      nodes {
        Committee_Name
        Seat_Holder
        Seat_Identifier
        Seat_Name__formula_
        Seat_Specific_Qualifications
        Term_Ending
        Open_to_the_General_Public
      }
    }
  }
`;
